import { inject, Injector, runInInjectionContext } from '@angular/core';
import { Store } from '@ngrx/store';
import { OnlineWsService } from '@solar/core/src';
import { take } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { IAppState } from '../../interfaces';
import { getUser, LoadPage, selectPageByName } from '../../store';

// Теперь appInit возвращает Promise<void> и принимает injector
export const appInit = (injector: Injector): Promise<void> => {
	return runInInjectionContext(injector, () => {
		const authService = inject(AuthService);
		const store = inject<Store<IAppState>>(Store);
		const onlineWsService = inject(OnlineWsService);

		onlineWsService.connectUser();

		if (!authService?.principal) {
			return Promise.resolve();
		}

		return new Promise<void>(resolve => {
			store
				.select(selectPageByName(getUser))
				.pipe(take(1))
				.subscribe(user => {
					if (!user && authService?.principal?.id) {
						store.dispatch(LoadPage({ path: getUser, reqData: { id: authService.principal.id } }));
					}
					resolve();
				});
		});
	});
};
