import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BalancesRes } from '@solar/core/src/store';
import { lastValueFrom, Observable } from 'rxjs';
import { ICollectionResponse } from '../../../interfaces';

@Injectable({
	providedIn: 'root',
})
export class BalancesService {
	constructor(private readonly http: HttpClient) {}

	public loadUserBalanceInfo(params: {
		user_id: number;
		from: string;
		to: string;
		amount: string;
	}): Observable<ICollectionResponse<BalancesRes>> {
		return this.http.get<ICollectionResponse<BalancesRes>>(`balances/info`, { params });
	}

	public loadAll(): Observable<ICollectionResponse<BalancesRes>> {
		return this.http.get<ICollectionResponse<BalancesRes>>(`balances`);
	}

	public update(body: BalancesRes): Observable<ICollectionResponse<BalancesRes>> {
		return this.http.patch<ICollectionResponse<BalancesRes>>(`balances`, body);
	}

	public async updateBalanceAsync(body: BalancesRes): Promise<ICollectionResponse<BalancesRes>> {
		try {
			return await lastValueFrom(this.http.patch(`balances`, body));
		} catch (e) {
			console.log(e);
		}
	}

	public async addUsdtWallet(body: { user_id }): Promise<ICollectionResponse<{ id: number; address: string }>> {
		try {
			return await lastValueFrom(this.http.post(`tron-usdt/create`, body));
		} catch (e) {
			console.log(e);
		}
	}
}
