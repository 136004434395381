export interface IClienstNotesDto {
	id: number;
	master_id: number;
	client_id?: number;
	client_name: string;
	client_phone: string;
	client_img: string;
	order_id?: string;
	created_at: string;
	message: string;
	likes: number;
	master_username?: string;
	master_img?: string;
}
