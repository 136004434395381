import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard {
	constructor(
		private readonly authService: AuthService,
		private readonly router: Router,
	) {}

	public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		if (this.authService.token) {
			return true;
		}
		void this.router.navigate(['/login']);
		return false;
	}
}
