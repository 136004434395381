import { Injectable, signal, WritableSignal } from '@angular/core';
import { IChat } from '@solar/core/src';
import { IChatMembers } from '../../../interfaces/chats/chat-members.interface';

@Injectable({
	providedIn: 'root',
})
export class ChatMembersStateService {
	public chatData: WritableSignal<IChat> = signal(null);
	public authorizedMember: WritableSignal<IChatMembers> = signal(null);

	constructor() {}
}
