import { ChangeDetectionStrategy, Component, inject, input, InputSignal } from '@angular/core';
import { Clipboard } from '@capacitor/clipboard';
import { IonicModule, ToastController } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { QrCodeModule } from 'ng-qrcode';

@Component({
	selector: 'hb-wallet-address',
	templateUrl: './wallet-address.component.html',
	styleUrls: ['./wallet-address.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [IonicModule, QrCodeModule, TranslateModule],
})
export class WalletAddressComponent {
	public balance = input(null);
	public walletTextLabel: InputSignal<string> = input('YOUR_USDT_TRC20_ADDRESS');

	private readonly toast = inject(ToastController);
	private readonly translate = inject(TranslateService);

	public async writeToClipboard(): Promise<void> {
		await Clipboard.write({
			string: this.balance().address,
		});

		await this.presentToast(this.balance().address + ' ' + this.translate.instant('COPIED'));
	}

	public async presentToast(message: string): Promise<void> {
		const toast = await this.toast.create({
			message,
			duration: 1000,
			position: 'bottom',
			icon: 'document-text-outline',
		});

		await toast.present();
	}
}
