import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ITransactionsDto, TransactionsService } from '../../rest/';

@Injectable({
	providedIn: 'root',
})
export class UsdtTransactionsStateService {
	private readonly restService = inject(TransactionsService);

	public res: WritableSignal<ITransactionsDto[]> = signal(null);

	public async fetchData(data: { wallet_address: string; stripe_connect_account_id?: string }): Promise<void> {
		const res = await lastValueFrom(this.restService.loadUsdtTransactions(data));

		this.res.set(res.data);
	}
}
