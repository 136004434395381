import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthGuard } from './auth.guard';
import { AUTH_SERVICE_CONFIG, AuthService, AuthServiceConfig } from './auth.service';

@NgModule({ declarations: [], imports: [], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class AuthModule {
	static forRoot(config: AuthServiceConfig): ModuleWithProviders<AuthModule> {
		return {
			ngModule: AuthModule,
			providers: [
				{
					provide: AUTH_SERVICE_CONFIG,
					useValue: config,
				},
				AuthService,
				AuthGuard,
			],
		};
	}
}
