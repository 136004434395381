import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { from, Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { FirebaseAuthService } from '../';
import { AuthService } from '../auth/auth.service';
import { ServerError } from '../types';

export interface InvalidParamDTO {
	name: string;
	reason: string[];
}

export interface ErrorDTO {
	fieldErrors?: InvalidParamDTO[];
	title?: string;
	type?: string;
}

const defaultServerError: ServerError = {
	message: 'Unknown error',
	fieldErrors: [],
};

const mapError = (error: ErrorDTO): ServerError => {
	return {
		message: error.title || 'Unknown error',
		fieldErrors: (error['fieldErrors'] || []).map(err => ({
			name: err.name,
			messages: err.reason,
		})),
	};
};

export enum API_ERROR_STATUSES {
	UNAUTHORIZED = 401,
	NO_PERMISSION = 403,
	SHOW_MESSAGE = 409,
	UNPROCESSABLE = 422,
	TO_MANY_REQUESTS = 429,
	ANY_ERROR = 400,
	SERVER_ERROR = 500,
}

const shouldShowErrorMessage = (status: API_ERROR_STATUSES): boolean => {
	return (
		status >= API_ERROR_STATUSES.SERVER_ERROR ||
		status === API_ERROR_STATUSES.SHOW_MESSAGE ||
		status === API_ERROR_STATUSES.ANY_ERROR ||
		status === API_ERROR_STATUSES.TO_MANY_REQUESTS ||
		status === API_ERROR_STATUSES.UNAUTHORIZED ||
		status === API_ERROR_STATUSES.NO_PERMISSION ||
		status === API_ERROR_STATUSES.UNPROCESSABLE
	);
};

@Injectable()
export class ErrorsMapInterceptor implements HttpInterceptor {
	public readonly MAX_RETRY_ATTEMPTS = 3;
	private readonly RETRY_DELAY_MS = 1000;

	private readonly authService = inject(AuthService);
	private readonly toastController = inject(ToastController);
	private readonly fbService = inject(FirebaseAuthService);
	private readonly router = inject(Router);

	public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(request).pipe(
			catchError((errorResponse: HttpErrorResponse) => {
				const { error, status } = errorResponse;

				// Исправлено: явно сравниваем числовое значение с числовым значением
				if (status === +API_ERROR_STATUSES.UNAUTHORIZED && this.authService?.principal) {
					return this.handleUnauthorized(request, next);
				}

				// Если ошибка уже в нужном формате, пробрасываем её дальше
				if (error?.message && error?.fieldErrors) {
					return throwError(() => error);
				}

				// Показываем сообщение об ошибке, если необходимо
				if (shouldShowErrorMessage(status)) {
					const errorMessage = error?.message || error?.title || error?.error || 'Unknown error';
					void this.presentToast(errorMessage);

					// Для критических ошибок можно перенаправить на специальную страницу
					if (status >= +API_ERROR_STATUSES.SERVER_ERROR) {
						void this.router.navigate(['/server-error']);
					}
				}

				// Преобразуем ошибку в стандартный формат
				return throwError(() => ({ error: error ? mapError(error) : defaultServerError }));
			}),
		);
	}

	/**
	 * Обрабатывает ситуацию с истекшим токеном авторизации
	 */
	private handleUnauthorized(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return from(this.refreshToken()).pipe(
			switchMap(tokenRefreshed => {
				if (tokenRefreshed) {
					// Если токен успешно обновлен, повторяем запрос с новым токеном
					const updatedRequest = request.clone({
						setHeaders: {
							Authorization: `Bearer ${this.authService.principal?.token}`,
						},
					});

					// Перенаправляем на главную, если пользователь на странице логина
					if (this.router.url === '/login') {
						void this.router.navigate(['/']);
					}

					return next.handle(updatedRequest);
				} else {
					// Если не удалось обновить токен, выходим из системы
					void this.authService.logout();
					void this.router.navigate(['/login']);
					return throwError(
						() =>
							new HttpErrorResponse({
								error: { message: 'Session expired. Please login again.' },
								status: API_ERROR_STATUSES.UNAUTHORIZED,
							}),
					);
				}
			}),
		);
	}

	/**
	 * Пытается обновить токен авторизации
	 * @returns Promise<boolean> - успешно ли обновлен токен
	 */
	private async refreshToken(): Promise<boolean> {
		let attempts = 0;

		while (attempts < this.MAX_RETRY_ATTEMPTS) {
			try {
				const firebaseToken = await this.fbService.getIdToken();

				if (firebaseToken) {
					this.authService.loadAndSetUserData({
						...this.authService.principal,
						token: firebaseToken,
					});
					return true;
				}

				// Ждем перед следующей попыткой
				await new Promise(resolve => setTimeout(resolve, this.RETRY_DELAY_MS));
				attempts++;
			} catch (error) {
				attempts++;
			}
		}

		return false;
	}

	protected async presentToast(message: string): Promise<void> {
		const toast = await this.toastController.create({
			message,
			duration: 1500,
			position: 'bottom',
		});

		void toast.present();
	}
}
