import { BalanceTypes } from './../../enums/balance-types.enum';
export interface IBalances {
	id: number;
	currency: string;
	amount: number;
	user_id: number;
	created_at: string;
	address: string;
	logo: string;
	available: number;
	type: BalanceTypes;
	stripe_connect_account_id: string;
}
