import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Clipboard } from '@capacitor/clipboard';
import { AlertController, IonicModule, ModalController, ToastController } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthService } from '@solar/core/src';
import { IOrders } from '@solar/core/src/services/rest/orders/orders.dto';
import { OrderStatusesEnum } from '@solar/enums/order-statuses.enum';
import { UserRolesEnum } from '@solar/enums/user-roles.enum';
import { ClientsNotesComponent, ShowOnlyForDirective } from '@solar/shared';
import { LocalizedCurrencyPipe } from '../../../../pipes/localized-currency.pipe';
import { ServerTranslatePipe } from '../../../../pipes/server-translate.pipe';
import { AvatarComponent } from '../../../stateless/avatar/avatar.component';
import { EditedDateComponent } from '../../edited-date/edited-date.component';

@Component({
	selector: 'hb-admin-orders-list-item',
	templateUrl: './admin-orders-list-item.component.html',
	styleUrls: ['./admin-orders-list-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		IonicModule,
		AvatarComponent,
		DatePipe,
		TranslateModule,
		LocalizedCurrencyPipe,
		ServerTranslatePipe,
		RouterLink,
		ShowOnlyForDirective,
		EditedDateComponent,
	],
})
export class AdminOrdersListItemComponent {
	protected readonly UserRolesEnum = UserRolesEnum;
	@Input() public statuses = OrderStatusesEnum;

	@Input() public it: IOrders;

	@Output() public search = new EventEmitter<string>();
	@Output() public menuEvent = new EventEmitter<IOrders>();

	protected readonly OrderStatusesEnum = OrderStatusesEnum;

	protected isClosed = true;

	constructor(
		private readonly toastController: ToastController,
		private readonly translate: TranslateService,
		protected readonly authService: AuthService,
		protected readonly alertController: AlertController,
		private readonly modalCtrl: ModalController,
	) {}

	public sumServicesTimes(hours): string | number {
		const arrHours: string = hours.split(',');

		if (arrHours.length <= 1) {
			return arrHours[0];
		}

		return arrHours[0] + '-' + arrHours[arrHours.length - 1];
	}

	public async writeToClipboard(string: string): Promise<void> {
		await Clipboard.write({
			string,
		});

		await this.presentToast(string + ' ' + this.translate.instant('COPIED'));
	}

	public async presentToast(message: string): Promise<void> {
		const toast = await this.toastController.create({
			message,
			duration: 1500,
			position: 'top',
			icon: 'document-text-outline',
		});

		await toast.present();
	}

	public onSearch(it): void {
		this.search.emit(it.phone || it.client_phone);
	}

	public statusColor = (order_status_id: OrderStatusesEnum): string => {
		switch (order_status_id) {
			case OrderStatusesEnum.CLOSE_DATE:
			case OrderStatusesEnum.PENDING_ACCEPTANCE:
				return 'warning';
			case OrderStatusesEnum.ACCEPTED:
			case OrderStatusesEnum.PAID:
				return 'success';
			default:
				'medium';
		}
	};

	public async onUpsertNote(it): Promise<void> {
		const clientIsMe = this.authService.principal?.id === it.client_id || this.authService.principal?.id === it.user_id;
		const isAdmin = it.client_role === UserRolesEnum.ADMIN;

		const obj = {
			master_id: this.authService.principal.id,
			client_id: !clientIsMe && it.client_id ? it.client_id : null,
			client_phone: it.phone || it.client_phone,
			client_name: !clientIsMe && it.client_user_name && !isAdmin ? it.client_user_name : it.name,
			client_img: !clientIsMe && it.client_img && !isAdmin ? it.client_img : null,
		};

		await this.openModal(obj);
	}

	public async openModal(client): Promise<void> {
		if (client) {
			const modal = await this.modalCtrl.create({
				component: ClientsNotesComponent,
				componentProps: { client },
			});

			void modal.present();

			await modal.onWillDismiss();

			this.search.emit();
		}
	}

	public onAccordion(ev): void {
		this.isClosed = !ev.detail.value;
	}
}
