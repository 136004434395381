import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { CategoryServicesRes } from '@solar/core/src/store';
import { lastValueFrom } from 'rxjs';
import { ISubCategoriesDto, ISubCategoriesModel } from '../../rest/sub-categories/sub-categories.dto';
import { SubCategoriesService } from '../../rest/sub-categories/sub-categories.service';

export interface ISubCategoriesWithServicesDto extends ISubCategoriesDto {
	services?: CategoryServicesRes[]; // Добавляем новое свойство
}

@Injectable({
	providedIn: 'root',
})
export class SubCategoriesStateService {
	private readonly subCategoriesService = inject(SubCategoriesService);

	public readonly contentScroll: WritableSignal<CustomEvent> = signal(null);

	public items: WritableSignal<ISubCategoriesWithServicesDto[]> = signal([]);

	constructor() {}

	public async fetchData({ parent_id }): Promise<void> {
		const data: ISubCategoriesDto[] = await lastValueFrom(this.subCategoriesService.getAllSubCategories({ parent_id }));

		this.items.set(data);
	}

	public async create(data: ISubCategoriesModel): Promise<void> {
		const res: ISubCategoriesDto = await lastValueFrom(this.subCategoriesService.createSubCategory(data));

		this.items.update(items => [...items, res]);
	}

	public async changePositions(data: ISubCategoriesWithServicesDto[]): Promise<void> {
		await lastValueFrom(this.subCategoriesService.changePositions(data));
	}

	public async edit(data: ISubCategoriesDto): Promise<void> {
		const res: ISubCategoriesDto = await lastValueFrom(this.subCategoriesService.editSubCategory(data));

		if (this.items.length) {
			this.items.update(items => items.map(item => (item.id === res.id ? res : item)));
			return;
		}

		await this.fetchData({ parent_id: res.parent_id });
	}

	public async delete({ id }): Promise<void> {
		await lastValueFrom(this.subCategoriesService.deleteSubCategory({ id }));

		this.items.update(items => items.filter(item => item.id !== id));
	}
}
