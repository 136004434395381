import { NgClass, TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { IonicModule, ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { IAppState, SubCategoriesStateService } from '@solar/core/src';
import { StoreServiceUtil } from '@solar/core/src/services/utils';
import { CategoriesRes, LoadPage } from '@solar/core/src/store';
import { ShopCategoryEditComponent } from '../../../../../../src/app/pages/shop-categories/shop-category-edit/shop-category-edit.component';
import { ServerTranslatePipe } from '../../../pipes/server-translate.pipe';
import { loadOnlyCategories } from './../../../../../core/src/store/categories/selectors';

@Component({
	selector: 'hb-categories-select',
	templateUrl: './categories-select.component.html',
	styleUrls: ['./categories-select.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [ReactiveFormsModule, IonicModule, ShopCategoryEditComponent, TitleCasePipe, TranslateModule, ServerTranslatePipe, NgClass],
})
export class CategoriesSelectComponent implements OnInit {
	@Input() public form: FormGroup;
	@Input() public controlName: string = 'categories';
	@Input() public multiple: boolean = false;
	@Input() public mode: 'ios' | 'md' = 'md';
	@Input() public parentData: CategoriesRes;
	@Output() public onChangeEvent: EventEmitter<void> = new EventEmitter();
	public subCategoriesStateService: SubCategoriesStateService = inject(SubCategoriesStateService);
	public router = inject(Router);
	public modalCtrl = inject(ModalController);

	constructor(
		private readonly store: Store<IAppState>,
		private readonly storeUtils: StoreServiceUtil,
	) {}

	public ngOnInit(): void {
		this.store.dispatch(LoadPage({ path: loadOnlyCategories, reqData: { model: 'category' } }));
	}

	public compareWith(o1, o2): boolean {
		// Проверяем, является ли o1 строкой и содержит ли она запятые
		if (typeof o1 === 'string' && o1.includes(',')) {
			// Разбиваем строку на массив чисел
			const arr1 = o1.split(',').map(Number);
			// Проверяем, есть ли o2 в arr1
			return arr1.includes(o2);
		} else {
			// Преобразуем o1 в число
			const val1 = Number(o1);
			// Преобразуем o2 в число
			const val2 = Number(o2);
			// Сравниваем числа
			return val1 === val2;
		}
	}

	public goToShop(): void {
		void this.modalCtrl.dismiss();
		void this.router.navigate(['/shop-categories'], { state: { parent: this.parentData } });
	}
}
