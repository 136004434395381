import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { IAppState } from '@solar/core/src/';
import { IMaster } from '../../../../../core/src/interfaces/masters/masters.interface';
import { loadBalanceInfo } from '../../../../../core/src/store/balances/selectors';
import { LoadPage } from '../../../../../core/src/store/pages/actions';
import { selectPageByName } from '../../../../../core/src/store/pages/selectors';
import { WalletAddressComponent } from '../../stateless/wallet-address/wallet-address.component';

@Component({
	selector: 'hb-order-crypto-payment',
	templateUrl: './order-crypto-payment.component.html',
	styleUrls: ['./order-crypto-payment.component.scss'],
	imports: [IonicModule, TranslateModule, WalletAddressComponent, AsyncPipe],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderCryptoPaymentComponent implements OnInit {
	@Input() public master: IMaster;
	@Input() public from: string;
	@Input() public to: string;
	@Input() public total: string;

	public loadBalanceInfo$ = this.store.select(selectPageByName(loadBalanceInfo));

	constructor(
		private readonly modalCtrl: ModalController,
		private readonly store: Store<IAppState>,
	) {}

	public ngOnInit(): void {
		this.store.dispatch(
			LoadPage({ path: loadBalanceInfo, reqData: { user_id: this.master.id, from: this.from, to: this.to, amount: this.total } }),
		);
	}

	public closeModal(): void {
		void this.modalCtrl.dismiss();
	}

	public confirmPayment(): void {
		void this.modalCtrl.dismiss('confirmed');
	}
}
