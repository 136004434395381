import { environment } from './../../../../../../src/environments/environment';
import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { HTTP_BASE_URL, OnlineWsService } from '@solar/core/src';
import { UserRes } from '@solar/core/src/store';

export interface IAvatar extends UserRes {
	user_id?: number;
	img: string;
	user_name: string;
}

type AvatarOrUser = IAvatar | UserRes;

@Component({
	selector: 'hb-avatar',
	templateUrl: './avatar.component.html',
	styleUrls: ['./avatar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [IonicModule, NgClass],
})
export class AvatarComponent implements OnInit {
	@Input() public data: AvatarOrUser;
	@Input() public slot: 'start' | 'end' = 'start';
	@Input() public imgUrl: string;
	@Input() public showOnlineDot = true;

	@Input() public size: 'large' | 'medium' | 'small' | 'extra-small' | 'emoji-size';

	public initials: string;

	public env = environment;

	constructor(
		@Inject(HTTP_BASE_URL) protected readonly httpBaseUrl: string,
		protected onlineUsers: OnlineWsService,
	) {}

	public ngOnInit(): void {
		this.createInitials();
	}

	private createInitials(): void {
		const userName = this.data.user_name?.trim() ?? '';

		// Add initial from user name
		if (userName.length > 0) {
			this.initials = userName.charAt(0).toUpperCase();
		} else {
			this.initials = '🤓';
		}
	}

	protected isAvatar(data: AvatarOrUser): data is IAvatar {
		return 'user_id' in data;
	}
}
