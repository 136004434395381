import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ICollectionResponse } from '../../../interfaces';
import { IClienstNotesDto } from './clients-notes.dto';

@Injectable({
	providedIn: 'root',
})
export class ClientsNotesService {
	constructor(private readonly http: HttpClient) {}
	public loadAll(params: { client_id?: number; client_phone: string }): Observable<ICollectionResponse<IClienstNotesDto>> {
		return this.http.get<ICollectionResponse<IClienstNotesDto>>(`clients-notes`, { params });
	}

	public add(body: IClienstNotesDto): Observable<IClienstNotesDto> {
		return this.http.post<IClienstNotesDto>(`clients-notes`, body);
	}

	public delete(it: { id: number }): Observable<void> {
		return this.http.delete<void>(`clients-notes/${it.id}`);
	}
}
