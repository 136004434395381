<div class="ion-text-center font-size-18 ion-padding ion-margin">
	<div class="flex ion-align-items-center ion-justify-content-center">
		<div class="check-container">
			<div class="check-background">
				<svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M7 25L27.3077 44L58.5 7" stroke="white" stroke-width="8" stroke-linecap="round" stroke-linejoin="round" />
				</svg>
			</div>
			<div class="check-shadow"></div>
		</div>
	</div>
</div>
