import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ITransactionsDto, TransactionsService } from '../../rest/';
import { IUsdtFeeDto } from './../../rest/transactions/transactions.dto';

@Injectable({
	providedIn: 'root',
})
export class UsdtSendStateService {
	private readonly restService = inject(TransactionsService);

	public res: WritableSignal<ITransactionsDto[]> = signal(null);

	public async checkAddress(data: { address: string }): Promise<{
		message: string;
		success: boolean;
		is_valid: boolean;
	}> {
		return await lastValueFrom(this.restService.checkAddress(data));
	}

	public async checkFee(data: { from_address: string; to_address: string; amount: string }): Promise<IUsdtFeeDto> {
		return await lastValueFrom(this.restService.checkFee(data));
	}

	public async send(data: { from_address: string; to_address: string; amount: string }): Promise<IUsdtFeeDto> {
		return await lastValueFrom(this.restService.send(data));
	}
}
