import { TransactionStatuses } from './../../../enums/transaction-statuses.enum';
export interface ITransactionsDto {
	id: number;
	to_address: string;
	from_address: string;
	timestamp: string;
	amount?: string;
	token_symbol: string;
	transaction_id: string | null;
	status: TransactionStatuses;
}

export interface IUsdtFeeDto {
	fee_in_trx: number;
	fee_in_usd: number;
	energy_consumption: number;
	bandwidth_consumption: number;
	trx_rate_usd: number;
}
