import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, output, signal } from '@angular/core';
import { Haptics } from '@capacitor/haptics';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { IOrders } from '@solar/core/src/services/rest/orders/orders.dto';
import { normalizeTime } from '@solar/core/src/utils/date';

export interface IHours {
	id?: number;
	colSize: string;
	label: string;
	isDisabled: boolean;
	checked?: boolean;
	order?: IOrders;
}

@Component({
	selector: 'hb-hours-grid',
	templateUrl: './hours-grid.component.html',
	styleUrls: ['./hours-grid.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [IonicModule, TranslateModule, NgClass],
})
export class HoursGridComponent {
	public isDisabled = input<boolean>(false);

	public infoBox = input<boolean>(true);

	public hours = input<IHours[]>([]);

	public selectedTime = input<string>('');

	public maxLength = input<number>(20);

	public selected = output<string>();

	protected isToastOpen = signal<boolean>(false);

	public onSelectTime(time: string): void {
		this.setOpen(false);
		this.selected.emit(normalizeTime(time));
		void Haptics.selectionChanged().then();
	}

	protected setOpen(isOpen: boolean): void {
		this.isToastOpen.set(isOpen);
	}
}
