import { Injectable } from '@angular/core';
import { BalancesService } from '../../services';
import { FormEffects } from '../forms';
import { PagesEffects } from '../pages';
import { loadBalanceInfo, loadBalances, updateBalance } from './selectors';

@Injectable()
export class BalancesEffects {
	public readonly loadBalanceInfo$ = this.pagesEffects.getLoadPageEffect(
		loadBalanceInfo,
		(req: { reqData: { user_id: number; from: string; to: string; amount: string } }) =>
			this.descriptionsService.loadUserBalanceInfo(req.reqData),
	);

	public readonly loadAll$ = this.pagesEffects.getLoadPageEffect(loadBalances, () => this.descriptionsService.loadAll());

	public readonly updateBalance$ = this.formEffects.getSendFormEffect(updateBalance, formState =>
		this.descriptionsService.update(formState.formData),
	);

	constructor(
		private readonly pagesEffects: PagesEffects,
		private readonly formEffects: FormEffects,
		private readonly descriptionsService: BalancesService,
	) {}
}
