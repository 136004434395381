import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplePayEventsEnum, PaymentSheetEventsEnum, Stripe } from '@capacitor-community/stripe';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from '../../../../../src/environments/environment';
import { IPaymentData } from '../../interfaces';
import { CategoryServicesRes } from '../../store';

@Injectable({
	providedIn: 'root',
})
export class PaymentService {
	constructor(
		private readonly http: HttpClient,
		private readonly loadingCtrl: LoadingController,
		private readonly translateService: TranslateService,
	) {
		void Stripe.initialize({
			publishableKey: environment.stripePublicKey,
		});
	}

	public httpPost(body: IPaymentData): Observable<{ paymentIntent: string; ephemeralKey: string; customer: string }> {
		return this.http.post<{ paymentIntent: string; ephemeralKey: string; customer: string }>('/payment-sheet', body).pipe(first());
	}

	public async paymentSheet(data: IPaymentData): Promise<boolean> {
		try {
			await this.showLoading();
			const { paymentIntent, ephemeralKey, customer } = await this.httpPost(data).toPromise();

			if (!data.payment_method) {
				await Stripe.createPaymentSheet({
					paymentIntentClientSecret: paymentIntent,
					customerId: customer,
					customerEphemeralKeySecret: ephemeralKey,
					merchantDisplayName: 'hey, BEAUTY',
					enableApplePay: true,
					enableGooglePay: true,
					applePayMerchantId: 'merchant.app.heybeauty',
					style: 'alwaysDark',
					withZipCode: false,
				});

				const result = await Stripe.presentPaymentSheet();
				await this.loadingCtrl.dismiss();

				if (result.paymentResult === PaymentSheetEventsEnum.Completed) {
					return true;
				} else {
					console.error('Payment failed:', result);
					return false;
				}
			}

			await this.loadingCtrl.dismiss();

			return true;
		} catch (error) {
			console.error('Error processing payment:', error);
			await this.loadingCtrl.dismiss();
			return false;
		}
	}

	public async createApplePay(data: {
		name: string;
		phone: string;
		amount: number;
		currency: string;
		serviceName: string;
		items: CategoryServicesRes[];
	}): Promise<boolean> {
		try {
			await this.showLoading();
			const priceToCents = this.toCents(data.amount);

			const { paymentIntent } = await this.httpPost({ ...data, amount: priceToCents }).toPromise();

			await Stripe.createApplePay({
				paymentIntentClientSecret: paymentIntent,
				paymentSummaryItems: [
					{
						label: data.serviceName,
						amount: this.formatItemsForApplePay(data.items),
					},
				],
				merchantIdentifier: 'merchant.app.heybeauty',
				countryCode: 'CY',
				currency: data.currency,
			});

			const result = await Stripe.presentApplePay();
			await this.loadingCtrl.dismiss();

			if (result.paymentResult === ApplePayEventsEnum.Completed) {
				return true;
			} else {
				return false;
			}
		} catch (error) {
			await this.loadingCtrl.dismiss();
		}
	}

	private async showLoading(): Promise<void> {
		const loading = await this.loadingCtrl.create({
			message: this.translateService.instant('PAYMENT_PROCESSING'),
			backdropDismiss: true,
			mode: 'md',
			spinner: 'lines',
		});

		void loading.present();
	}

	public totalPrice(orders: CategoryServicesRes[]): number {
		return orders.reduce((acc, order) => +acc + +order.price, 0); // convert to cents
	}

	public formatItemsForApplePay(products: CategoryServicesRes[]): number {
		const total = products.reduce((sum, product) => {
			return sum + parseFloat(String(product.price));
		}, 0);

		// Округляем до двух знаков после запятой и возвращаем как число
		return Math.round(total * 100) / 100;
	}

	public totalPriceToCents(orders: CategoryServicesRes[]): number {
		const totalPrice = this.totalPrice(orders);

		return this.toCents(totalPrice);
	}

	public toCents(price: number): number {
		return price * 100;
	}

	public async checkApplePayAvailability(): Promise<boolean> {
		try {
			await Stripe.isApplePayAvailable();
			return Promise.resolve(true);
		} catch (error) {
			console.error('Error checking Apple Pay availability:', error);
			return Promise.resolve(false);
		}
	}
}
