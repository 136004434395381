import { Location } from '@angular/common';
import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlMasks } from '../enums';

export const HTTP_BASE_URL = new InjectionToken<string>('HTTP_BASE_URL');

const urlHasSchema = (url: string) => url && (url.startsWith('http://') || url.startsWith('https://'));
const isLocalizationRequest = (url: string) => url && url.includes('.json');

/**
 * Функциональный HTTP интерцептор для добавления базового URL к относительным запросам.
 * HTTP_BASE_URL должен быть предоставлен через DI.
 */
export const baseUrlInterceptor: HttpInterceptorFn = (
	request: HttpRequest<unknown>,
	next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
	const httpBaseUrl = inject(HTTP_BASE_URL);

	// Добавляем базовый URL, если он отсутствует
	if (!urlHasSchema(request.url) && !isLocalizationRequest(request.url)) {
		request = request.clone({
			url: Location.joinWithSlash(httpBaseUrl, request.url),
		});
	}

	request = replaceMasks(request);

	return next(request);
};

const replaceMasks = (req: HttpRequest<any>): HttpRequest<any> => {
	if (req.url.includes(UrlMasks.UserId)) {
		const authData = JSON.parse(localStorage.getItem('principal'));

		if (!authData) {
			console.warn('UrlInterceptor: no AuthData in LocalStorage');
			return req;
		}

		return req.clone({
			url: req.url.replace(UrlMasks.UserId, authData.id),
		});
	}
	return req;
};
