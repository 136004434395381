import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ClientsNotesService } from '../../rest/clients-notes/clients-notes.service';
import { IClienstNotesDto } from './../../rest/clients-notes/clients-notes.dto';

@Injectable({
	providedIn: 'root',
})
export class ClientsNotesStateService {
	private readonly restService = inject(ClientsNotesService);

	public res: WritableSignal<IClienstNotesDto[]> = signal(null);

	public async fetchData(data: { client_id?: number; client_phone: string }): Promise<void> {
		const res = await lastValueFrom(this.restService.loadAll(data));

		this.res.set(res.data);
	}

	public async add(item: IClienstNotesDto): Promise<void> {
		const res = await lastValueFrom(this.restService.add(item));

		this.res.update(items => [res, ...items]);
	}

	public async delete({ id }): Promise<void> {
		await lastValueFrom(this.restService.delete({ id }));

		this.res.update(items => items.filter(item => item.id !== id));
	}
}
