<ion-card mode="ios">
	<ion-card-content class="ion-text-center">
		<qr-code
			[value]="balance().address"
			size="230"
			errorCorrectionLevel="M"
			darkColor="#1d1d1d"
			[centerImageSrc]="balance().logo"
			[margin]="1"
		></qr-code>
		<div class="m-t-6">
			<ion-note>{{ 'SCAN_TO_SEND_USDT_TO_YOUR_WALLET' | translate }}</ion-note>
		</div>
	</ion-card-content>
</ion-card>

<ion-card mode="ios">
	<ion-card-content class="ion-text-center">
		<ion-note>{{ walletTextLabel() | translate }}</ion-note>

		<div class="m-b-4">
			<ion-text color="dark">{{ balance().address }}</ion-text>
		</div>
		<ion-button size="small" mode="ios" fill="outline" (click)="writeToClipboard()">
			<ion-icon slot="start" name="copy-outline"></ion-icon>
			{{ 'COPY_ADDRESS' | translate }}
		</ion-button>
	</ion-card-content>
</ion-card>
