import { Injectable, inject } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
	providedIn: 'root',
})
export class ToastService {
	private readonly toastController = inject(ToastController);
	private readonly translateService = inject(TranslateService);

	public async presentToast(
		message: string,
		duration = 2000,
		type = 'success',
		position: 'top' | 'bottom' | 'middle' = 'bottom',
		isDismiss = false,
	): Promise<void> {
		const isToast = await this.toastController.getTop();

		if (!isToast && message) {
			const toast = await this.toastController.create({
				message: this.translateService.instant(message),
				duration,
				cssClass: type === 'error' ? 'text-error' : type,
				position,
				color: 'light',
				buttons: isDismiss && [
					{
						text: this.translateService.instant('OK'),
						role: 'cancel',
					},
				],
				swipeGesture: 'vertical',
				translucent: true,
				mode: 'ios',
				positionAnchor: 'footer',
			});

			await toast.present();
		}
	}
}
