import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	effect,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	signal,
	ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Media, MediaSaveOptions } from '@capacitor-community/media';
import { Clipboard } from '@capacitor/clipboard';
import { Capacitor } from '@capacitor/core';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { AlertController, AnimationController, InfiniteScrollCustomEvent, IonicModule, LoadingController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
	ChatMembersStateService,
	generateUID,
	IMessage,
	MessageReactionsService,
	MessagesStateService,
	MessagesWsService,
	timeoutUtil,
	ToastService,
} from '@solar/core/src';
import { AuthService } from '@solar/core/src/auth/auth.service';
import { IAppState } from '@solar/core/src/interfaces';
import { addMessage, deleteMessage, SendForm } from '@solar/core/src/store';
import { ChatModalListItemComponent } from '@solar/shared/standalone/components/chat-modal-list/chat-modal-list-item/chat-modal-list-item.component';
import { EmojisModalComponent } from '../../../components/statefull/emojis-modal/emojis-modal.component';

@Component({
	selector: 'hb-chat-modal-list',
	templateUrl: './chat-modal-list.component.html',
	styleUrls: ['./chat-modal-list.component.scss'],
	imports: [IonicModule, TranslateModule, ChatModalListItemComponent, EmojisModalComponent],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatModalListComponent implements OnInit, OnDestroy {
	@ViewChild('messagesContainer', { static: false }) public messagesContainer: ElementRef;

	@Input() public categoryId: number;

	public formName: string = addMessage;

	public form: FormGroup;

	public isUserAtBottom = true;
	public isOnInitSet = false;

	public isModalOpen = false;
	public selectedImage: string | null = null;
	public selectedImageId: number | null = null;
	public selectedIndex: number = null;
	public message: string;
	public fileName: string;

	// For emojis modal
	public isEmojisOpen = false;
	public selectedMessage: IMessage;

	// for actions popover
	public isActionsOpen = false;

	public isPending = signal(false);

	public shortEmojiList = [
		{ value: '❤️', search_text: 'love, heart' },
		{ value: '👍', search_text: 'like, thumbs up' },
		{ value: '😂', search_text: 'laugh, tears of joy' },
		{ value: '😍', search_text: 'love, heart eyes' },
		{ value: '💯', search_text: '100, 100%, 10' },
	];

	@Output() public replyMessage: EventEmitter<IMessage> = new EventEmitter();
	@Output() public scrollToBottom: EventEmitter<string> = new EventEmitter();
	constructor(
		public readonly messagesStateService: MessagesStateService,
		private readonly animationCtrl: AnimationController,
		protected readonly messagesWsService: MessagesWsService,
		private readonly translate: TranslateService,
		private readonly alertController: AlertController,
		private readonly store: Store<IAppState>,
		private readonly toastService: ToastService,
		private readonly ch: ChangeDetectorRef,
		public readonly authService: AuthService,
		private readonly messageReactionsService: MessageReactionsService,
		protected readonly chatMembersState: ChatMembersStateService,
		private readonly loadingCtrl: LoadingController,
	) {
		effect(() => {
			const messages = this.messagesStateService.messages();

			if (messages?.data?.length && this.isUserAtBottom && this.isOnInitSet) {
				this.scrollToBottom.emit();
			}
		});
	}

	public async ngOnInit(): Promise<void> {
		await this.loadMessages();

		this.isOnInitSet = true;
	}

	public async loadMessages(): Promise<void> {
		this.isPending.set(true);

		if (this.messagesWsService.chatId()) {
			await this.messagesStateService.load({ chat_id: this.messagesWsService.chatId(), page: 1 });
		} else {
			await timeoutUtil();
			await this.loadMessages();
		}

		this.isPending.set(false);
	}

	public onFirstLoadScroll(): void {
		this.scrollToBottom.emit('firstLoad');
	}

	public onImageLoad(): void {
		if (this.isUserAtBottom) {
			this.scrollToBottom.emit();
		}
	}

	public trackByFn(index: number): number {
		return index;
	}

	public async onPushData(res): Promise<void> {
		await this.messagesStateService.loadPush({ page: ++res.page, chat_id: this.messagesWsService.chatId() });

		void timeoutUtil().then(() => {
			void (res.ev as InfiniteScrollCustomEvent).target.complete();
		});
	}

	public async onIonInfinite(ev, page: number, pages?: number): Promise<void> {
		this.isUserAtBottom = false;
		if (page >= pages) {
			void timeoutUtil().then(() => {
				void (ev as InfiniteScrollCustomEvent).target.complete();
			});
			return;
		}

		await this.onPushData({ ev, page });
	}

	public enterAnimation = (baseEl: any) => {
		const root = baseEl.shadowRoot;
		const button = document.querySelector(`#img-${this.selectedImageId}`);

		if (!button) {
			return this.animationCtrl.create().addElement(baseEl).duration(0); // Возвращаем пустую анимацию
		}

		const rect = button.getBoundingClientRect();
		const originX = rect.left + rect.width / 2 + 'px';
		const originY = rect.top + rect.height / 2 + 'px';

		const wrapperAnimation = this.animationCtrl
			.create()
			.addElement(root.querySelector('.modal-wrapper'))
			.keyframes([
				{ offset: 0, opacity: '0', transform: 'scale(0)', transformOrigin: `${originX} ${originY}` },
				{ offset: 1, opacity: '1', transform: 'scale(1)', transformOrigin: 'center center' },
			])
			.duration(300)
			.easing('ease-in-out');

		return wrapperAnimation;
	};

	public leaveAnimation = (baseEl: HTMLElement) => {
		const animation = this.enterAnimation(baseEl);
		if (animation.direction) {
			return animation.direction('reverse');
		}
		return animation; // Возвращаем пустую анимацию, если direction не поддерживается
	};

	public setOpenFile(event, index?: number): void {
		const { isOpen, img, id, message, fileName } = event;
		this.selectedImageId = id ? id : this.selectedImageId;
		this.selectedImage = img;
		this.selectedIndex = index;
		this.fileName = fileName;

		this.message = message;
		this.isModalOpen = isOpen;
	}

	public showEmojisModal({ isOpen, message }): void {
		this.isActionsOpen = false;
		this.selectedMessage = message;
		this.isEmojisOpen = isOpen;

		this.ch.detectChanges();
	}

	public async onDelete(id = this.selectedMessage?.id): Promise<void> {
		if (!id) {
			await this.toastService.presentToast('SERVER_ERROR');

			return;
		}

		const alert = await this.alertController.create({
			header: this.translate.instant('SURE_DELETE_THIS_MSG'),
			mode: 'ios',
			buttons: [
				{
					text: this.translate.instant('CANCEL'),
					role: 'cancel',
					handler: () => {},
				},
				{
					text: this.translate.instant('OK'),
					role: 'confirm',
					handler: () => {
						this.isActionsOpen = false;
						this.store.dispatch(SendForm({ formName: deleteMessage, formState: { formData: { id, categoryId: this.categoryId } } }));

						this.setOpenFile({ isOpen: false });
					},
				},
			],
		});

		await alert.present();
	}

	public onOpenActions(message): void {
		this.selectedMessage = message;
		this.isActionsOpen = true;

		this.ch.detectChanges();
	}

	public async writeToClipboard(string: string): Promise<void> {
		this.isActionsOpen = false;

		await Clipboard.write({
			string,
		});

		await this.presentToast(string + ' ' + this.translate.instant('COPIED'));
	}

	public async presentToast(message: string): Promise<void> {
		await this.toastService.presentToast(message);
	}

	public async setSelectedEmoji(emoji): Promise<void> {
		void Haptics.selectionChanged().then();

		// Удаляем старую реакцию этого пользователя, если она есть
		this.selectedMessage.reactions = this.selectedMessage?.reactions?.filter(reaction => {
			return !reaction.users.some(user => user.user_id === this.authService.principal.id);
		});

		// Добавляем новую реакцию
		const result = await this.messageReactionsService.addReaction({
			message_id: this.selectedMessage.id,
			reaction: emoji,
		});

		// Отправляем обновленное сообщение через WebSocket
		const uuid = generateUID();

		this.messagesWsService.send({
			uuid,
			...this.selectedMessage,
			reactions: result?.reactions,
		});

		// Закрываем модальное окно
		this.isActionsOpen = false;
	}

	public onReply(): void {
		void Haptics.impact({ style: ImpactStyle.Light }).then();

		this.isActionsOpen = false;
		this.replyMessage.emit(this.selectedMessage);
	}

	public async onDownload(): Promise<void> {
		await this.showLoading();

		if (Capacitor.getPlatform() === 'web') {
			try {
				// Fetch file data as Blob
				const response = await fetch(this.selectedImage);
				const blob = await response.blob();

				// Convert Blob to a downloadable object
				const objectUrl = URL.createObjectURL(blob);

				// Save file without needing to create a link manually
				const a = document.createElement('a');
				a.href = objectUrl;
				a.download = this.fileName || 'heyBEAUTY-image.jpg'; // Customize filename
				a.click();
				URL.revokeObjectURL(objectUrl); // Clean up
			} catch (error) {
				await this.loadingCtrl.dismiss();
			}
		} else {
			const opts: MediaSaveOptions = { path: this.selectedImage };
			await Media.savePhoto(opts);

			await this.toastService.presentToast('IMAGE_SUCCESS_DOWNLODED_TO_GALLERY');
		}

		await this.loadingCtrl.dismiss();
	}

	private async showLoading(): Promise<void> {
		const loading = await this.loadingCtrl.create({
			backdropDismiss: false,
			mode: 'md',
			spinner: 'lines',
			duration: 9000,
		});

		void loading.present();
	}

	public ngOnDestroy(): void {
		this.isOnInitSet = false;

		this.messagesWsService.chatId.set(null);
		this.messagesWsService.is_group.set(false);

		this.messagesStateService.messages.set({ data: [], page: 1, pages: 1 });
	}
}
