import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, CurrencyPipe, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizedCurrencyPipe } from './pipes/localized-currency.pipe';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		IonicModule,
		ReactiveFormsModule,
		FormsModule,
		RouterModule,
		ScrollingModule,
		NgOptimizedImage,
		LocalizedCurrencyPipe,
	],
	providers: [CurrencyPipe],
})
export class SharedModule {}
