import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, inject, Input, OnInit } from '@angular/core';
import { AlertController, IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthService } from '@solar/core/src';
import { UserRoleService } from '@solar/core/src/services/user-role/user-role.service';
import { defineCustomElements } from '@teamhive/lottie-player/loader';
import { AvatarComponent } from '../../stateless';
import { IClienstNotesDto } from './../../../../../core/src/services/rest/clients-notes/clients-notes.dto';
import { ClientsNotesStateService } from './../../../../../core/src/services/signals-states/clients-notes-state/clients-notes-state.service';

void defineCustomElements(window);

@Component({
	selector: 'hb-clients-notes',
	templateUrl: './clients-notes.component.html',
	styleUrls: ['./clients-notes.component.scss'],
	imports: [IonicModule, TranslateModule, AvatarComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientsNotesComponent implements OnInit {
	private readonly alertController = inject(AlertController);
	protected modalCtrl = inject(ModalController);
	private readonly translate = inject(TranslateService);
	protected readonly clientsNotesState = inject(ClientsNotesStateService);
	protected readonly authService = inject(AuthService);
	protected readonly userRoleService = inject(UserRoleService);

	@Input() public client: IClienstNotesDto;

	public async ngOnInit(): Promise<void> {
		await this.clientsNotesState.fetchData({ client_id: null, client_phone: this.client.client_phone });

		console.log(this.client);
	}

	public async presentAlert(): Promise<void> {
		const alert = await this.alertController.create({
			mode: 'ios',
			header: this.translate.instant('NOTE'),
			subHeader: this.translate.instant('NOTE_SUBHEADER_TEXT', { user: this.client.client_name }),
			buttons: [
				{
					text: this.translate.instant('CANCEL'),
					role: 'cancel',
					cssClass: 'color-medium',
				},
				{
					text: this.translate.instant('ADD'),
					role: 'confirm',
				},
			],
			inputs: [
				{
					type: 'textarea',
					placeholder: this.translate.instant('NOTE_PLACEHOLDER', { user: this.client.client_name }),
				},
			],
		});

		await alert.present();

		const { data, role } = await alert.onDidDismiss();
		const message = data?.values[0];

		if (role === 'confirm') {
			await this.clientsNotesState.add({ ...this.client, message });
		}
	}

	public async onDelete(it: IClienstNotesDto): Promise<void> {
		const alertButtons = [
			{
				text: 'No',
				cssClass: 'alert-button-cancel',
			},
			{
				text: 'Yes',
				cssClass: 'alert-button-confirm',
				handler: async () => {
					await this.clientsNotesState.delete({ id: it.id });
				},
			},
		];

		const confirm = await this.alertController.create({
			header: this.translate.instant('SURE_TO_DELETE_ITEM', { item: `'${it.message}'` }),
			buttons: alertButtons,
		});

		await confirm.present();
	}
}
