import { enableProdMode, importProvidersFrom, Injector, LOCALE_ID, provideAppInitializer } from '@angular/core';
import { baseUrlInterceptor } from './../libs/core/src/interceptors/base-url.interceptor';

import { NgOptimizedImage, registerLocaleData } from '@angular/common';
import {
	HTTP_INTERCEPTORS,
	HttpClient,
	HttpParams,
	provideHttpClient,
	withInterceptors,
	withInterceptorsFromDi,
} from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeRu from '@angular/common/locales/ru';
import { inject } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuth, AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideRouter, RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { IonicModule } from '@ionic/angular';
import { IonicRouteStrategy } from '@ionic/angular/standalone';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
	ErrorsMapInterceptor,
	HTTP_BASE_URL,
	HTTP_PARAMS_CONFIG,
	HTTP_STORAGE_TOKEN_KEY,
	httpParamsInterceptor,
	jsonInterceptor,
	StorageTokenInterceptor,
} from '@solar/core/src';
import { HamboCoreModule } from '@solar/core/src/hambo-core.module';
import { NoopInterceptor } from '@solar/core/src/interceptors/noop.interceptor';
import { appInit } from '@solar/core/src/services/app-init';
import { SharedModule } from '@solar/shared/shared.module';
import { defineCustomElements as defineCustomElementsStripe } from 'stripe-pwa-elements/loader';
import { AppComponent } from './app/app.component';
import { routes } from './app/app.routes';
import { OrdersService } from './app/core/services';
import { environment } from './environments/environment';
// import * as Sentry from '@sentry/angular';

export const interceptorsConfig: any = {
	baseUrl: environment.api,
	storageTokenKey: 'token',
	httpParamsFactory: () => new HttpParams(),
};

if (environment.production) {
	enableProdMode();
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
	return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

registerLocaleData(localeRu, 'ru');
registerLocaleData(localeEn, 'en');

// Sentry.init({
//     dsn: environment.sentryUrl,
//     release: packageInfo.version,
//     integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
//     // Performance Monitoring
//     tracesSampleRate: 0.1, //  Capture 100% of the transactions
//     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//     tracePropagationTargets: ['localhost', /^https:\/\/hey-beauty\.app\/api/],
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

bootstrapApplication(AppComponent, {
	providers: [
		importProvidersFrom(
			EffectsModule.forRoot([]),
			StoreModule.forRoot({}),
			StoreDevtoolsModule.instrument(),
			BrowserModule,
			IonicModule.forRoot({
				scrollAssist: false,
				scrollPadding: false,
			}),
			TranslateModule.forRoot({
				defaultLanguage: 'en',
				loader: {
					provide: TranslateLoader,
					useFactory: HttpLoaderFactory,
					deps: [HttpClient],
				},
			}),
			HamboCoreModule,
			SharedModule,
			NgOptimizedImage,
			ServiceWorkerModule.register('combined-sw.js', { enabled: environment.production }),
			AngularFireModule.initializeApp(environment.firebase),
			AngularFireMessagingModule,
			AngularFireAuthModule,
		),
		provideFirestore(() => getFirestore()),
		provideAuth(() => getAuth()),
		provideFirebaseApp(() => initializeApp(environment.firebase)),
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		OrdersService,
		{ provide: LOCALE_ID, useValue: 'en' },
		// {
		//     provide: ErrorHandler,
		//     useValue: Sentry.createErrorHandler({
		//         showDialog: false,
		//     }),
		// },
		// {
		//     provide: Sentry.TraceService,
		//     deps: [Router],
		// },
		provideAppInitializer(() => {
			const injector = inject(Injector); // Получаем Injector через функцию inject
			return appInit(injector); // Передаем injector в appInit
		}),
		AngularFireAuth,
		{
			provide: HTTP_BASE_URL,
			useValue: interceptorsConfig.baseUrl,
		},
		{
			provide: HTTP_STORAGE_TOKEN_KEY,
			useValue: interceptorsConfig.storageTokenKey,
		},
		{
			provide: HTTP_PARAMS_CONFIG,
			useValue: interceptorsConfig.httpParamsFactory,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: interceptorsConfig.storageTokenKey ? StorageTokenInterceptor : NoopInterceptor,
			deps: [HTTP_STORAGE_TOKEN_KEY],
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ErrorsMapInterceptor,
			multi: true,
			deps: [Injector],
		},
		provideHttpClient(withInterceptorsFromDi(), withInterceptors([httpParamsInterceptor, baseUrlInterceptor, jsonInterceptor])),
		provideRouter(routes),
	],
})
	.then(() => void defineCustomElements(window), void defineCustomElementsStripe(window))
	.catch(err => console.log(err));
