import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ICollectionResponse } from '../../../interfaces';
import { ITransactionsDto, IUsdtFeeDto } from './transactions.dto';

@Injectable({
	providedIn: 'root',
})
export class TransactionsService {
	constructor(private readonly http: HttpClient) {}
	public loadUsdtTransactions(params: {
		wallet_address?: string;
		stripe_connect_account_id?: string;
	}): Observable<ICollectionResponse<ITransactionsDto>> {
		return this.http.get<ICollectionResponse<ITransactionsDto>>(`transactions`, { params });
	}

	public checkAddress(body: { address: string }): Observable<{ is_valid: boolean; success: boolean; message: string }> {
		return this.http.post<{ is_valid: boolean; success: boolean; message: string }>(`tron-usdt/check-address`, body);
	}
	public checkFee(body: { from_address: string; to_address: string; amount: string }): Observable<IUsdtFeeDto> {
		return this.http.post<IUsdtFeeDto>(`tron-usdt/check-fee`, body);
	}

	public send(body: { from_address: string; to_address: string; amount: string }): Observable<IUsdtFeeDto> {
		return this.http.post<IUsdtFeeDto>(`tron-usdt/transfer`, body);
	}
}
