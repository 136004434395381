<div [formGroup]="form" class="flex-row list-width bg-light" [ngClass]="ngClass">
	<ion-item color="light" class="code-inp flex-1" style="--inner-padding-end: 0">
		<ion-input
			labelPlacement="floating"
			formControlName="code"
			[label]="'CODE' | translate"
			type="phone"
			readonly="true"
			(focusin)="onPopover()"
			[mode]="mode"
			[errorText]="'PHONE_ERROR' | translate"
		></ion-input>
	</ion-item>

	<ion-item color="light" class="phone-number flex-1" style="--padding-start: 0">
		<ion-input
			#phoneInput
			formControlName="phone"
			[maxlength]="maxLength"
			[minlength]="maxLength"
			labelPlacement="floating"
			type="tel"
			pattern="[0-9]*"
			[label]="'PHONE' | translate"
			(ionInput)="detectInputEvent($event)"
			(keyup)="fixCaretPosition($event)"
			[mode]="mode"
			[errorText]="(!phoneInput?.value ? 'PHONE_ERROR' : 'PHONE_IS_NOT_VALID') | translate"
			[counter]="true"
		>
		</ion-input>
	</ion-item>
</div>
