<ion-avatar [slot]="slot" class="hb-avatar margin-h-auto" [ngClass]="size">
	@if (imgUrl || data?.img) {
		<img [src]="imgUrl ? imgUrl : this.env.s3Url + '/' + data.img" alt="User avatar" />
	}

	@if (!data.img) {
		{{ initials }}
	}

	@if (showOnlineDot && onlineUsers.isOnline(data?.id || (isAvatar(data) ? data.user_id : undefined))) {
		<div class="online-icon font-size-12"></div>
	}
</ion-avatar>
