import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class PaymentsService {
	constructor(private readonly http: HttpClient) {}

	public getPaymentsList(body: { customerId: number }): Observable<any> {
		return this.http.get<any>(`/payment-methods/${body.customerId}`);
	}

	public payoutsToDestination(body: { amount: number; currency: string; destination: string }): Observable<any> {
		return this.http.post<any>(`/payouts`, body);
	}

	public checkStripeConnectAccountActivation(params: {
		account_id: number;
	}): Promise<{ charges_enabled: boolean; payouts_enabled: boolean }> {
		return lastValueFrom(
			this.http.get<{ charges_enabled: boolean; payouts_enabled: boolean }>(`/stripe-connect-account/check-activation`, { params }),
		);
	}

	public createStripeConnectAccount(body: {
		email: string;
		country?: string;
	}): Observable<{ id: number; stripe_connect_account_id: string }> {
		return this.http.post<{ id: number; stripe_connect_account_id: string }>(`/stripe-connect-account`, body);
	}

	public getStripeOnBoardingLink(body: { account_id: string }): Observable<{ url: string }> {
		return this.http.post<{ url: string }>(`/stripe-connect-account/onboarding`, body);
	}

	public loginStripe(params: { account_id: string }): Observable<{ url: string }> {
		return this.http.get<{ url: string }>(`/stripe-connect-account/login`, { params });
	}
}
