import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * Add 'Content-Type': 'application/json' to all requests
 */
export const jsonInterceptor: HttpInterceptorFn = (request: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> => {
	const localLang = localStorage.getItem('lang');
	const userLang = localLang ? localLang : navigator.language;
	const lang = userLang.includes('ru') ? 'ru-RU' : 'en-US';

	const modifiedRequest = request.clone({
		setHeaders: {
			'Accept-Language': lang,
		},
	});

	return next(modifiedRequest);
};
