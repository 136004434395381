import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { IMessage, MessageReactionsService, MessagesWsService } from '@solar/core/src';
import { AuthService } from '@solar/core/src/auth/auth.service';
import { generateUID } from '@solar/core/src/utils';
@Component({
	selector: 'hb-emojis-modal',
	templateUrl: './emojis-modal.component.html',
	styleUrls: ['./emojis-modal.component.scss'],
	imports: [IonicModule, TranslateModule],
})
export class EmojisModalComponent {
	public list = [
		{ value: '❤️', search_text: 'love, heart' },
		{ value: '👍', search_text: 'like, thumbs up' },
		{ value: '😂', search_text: 'laugh, tears of joy' },
		{ value: '💯', search_text: '100%, 100, 10' },
		{ value: '🥺', search_text: 'pleading, sad' },
		{ value: '😍', search_text: 'love, heart eyes' },
		{ value: '🎉', search_text: 'party, celebration, confetti' },
		{ value: '🤣', search_text: 'laugh, rolling on the floor' },
		{ value: '😊', search_text: 'smile, happy' },
		{ value: '🙏', search_text: 'thank you, prayer, folded hands' },
		{ value: '😭', search_text: 'crying, sad' },
		{ value: '😘', search_text: 'kiss, blowing kiss' },
		{ value: '😔', search_text: 'sad, pensive' },
		{ value: '😎', search_text: 'cool, sunglasses' },
		{ value: '😅', search_text: 'nervous, relief, sweat' },
		{ value: '🥰', search_text: 'love, hearts, smiling' },
		{ value: '😡', search_text: 'angry, mad' },
		{ value: '🤔', search_text: 'thinking, hmmm' },
		{ value: '🥳', search_text: 'party, celebration' },
		{ value: '👏', search_text: 'applause, clapping' },
		{ value: '💀', search_text: 'dead, skull, dying of laughter' },
		{ value: '😋', search_text: 'yum, delicious' },
		{ value: '🤗', search_text: 'hug, love, care' },
		{ value: '😏', search_text: 'smirk, sly' },
		{ value: '😢', search_text: 'crying, tear' },
		{ value: '😜', search_text: 'winking, tongue out' },
		{ value: '🤩', search_text: 'star-struck, amazed' },
		{ value: '😴', search_text: 'sleepy, tired' },
		{ value: '🤨', search_text: 'skeptical, hmm' },
		{ value: '🤤', search_text: 'drool, hungry' },
		{ value: '🤯', search_text: 'mind blown, shocked' },
		{ value: '😱', search_text: 'shocked, scream' },
		{ value: '😳', search_text: 'blushing, surprised' },
		{ value: '🙄', search_text: 'eyeroll, annoyed' },
		{ value: '😬', search_text: 'awkward, cringe' },
		{ value: '🤪', search_text: 'crazy, silly' },
		{ value: '😇', search_text: 'angel, innocent' },
		{ value: '😈', search_text: 'devil, mischievous' },
		{ value: '🤭', search_text: 'oops, giggle' },
		{ value: '😩', search_text: 'tired, sigh' },
		{ value: '💪', search_text: 'strong, muscle' },
		{ value: '🤝', search_text: 'handshake, deal' },
		{ value: '💖', search_text: 'heart, love, sparkle' },
		{ value: '👀', search_text: 'eyes, looking' },
		{ value: '😠', search_text: 'angry, frustrated' },
		{ value: '✌️', search_text: 'peace, victory' },
		{ value: '💔', search_text: 'heartbreak, broken' },
		{ value: '👋', search_text: 'wave, hello' },
		{ value: '👑', search_text: 'king, queen, crown' },
		{ value: '😌', search_text: 'relieved, peaceful' },
		{ value: '😷', search_text: 'sick, mask' },
		{ value: '🥶', search_text: 'cold, freezing' },
		{ value: '😮', search_text: 'surprised, open mouth' },
		{ value: '👊', search_text: 'fist bump, punch' },
		{ value: '🤮', search_text: 'vomit, disgusted' },
		{ value: '😫', search_text: 'tired, exhausted' },
		{ value: '😤', search_text: 'frustrated, steam' },
		{ value: '👶', search_text: 'baby, infant' },
		{ value: '🥵', search_text: 'hot, sweating' },
		{ value: '😕', search_text: 'confused, unsure' },
		{ value: '😒', search_text: 'unamused, displeased' },
		{ value: '🤬', search_text: 'angry, censored' },
		{ value: '🤓', search_text: 'nerd, glasses' },
		{ value: '👻', search_text: 'ghost, spooky' },
		{ value: '💃', search_text: 'dance, celebration' },
		{ value: '🎂', search_text: 'birthday, cake' },
		{ value: '🙃', search_text: 'upside down, sarcastic' },
		{ value: '🤡', search_text: 'clown, funny' },
		{ value: '😚', search_text: 'kiss, love' },
		{ value: '🥴', search_text: 'dizzy, woozy' },
		{ value: '👽', search_text: 'alien, extraterrestrial' },
		{ value: '🐱', search_text: 'cat, cute' },
		{ value: '🐶', search_text: 'dog, puppy' },
		{ value: '💘', search_text: 'heart, arrow, love' },
		{ value: '🤑', search_text: 'money, rich' },
		{ value: '👿', search_text: 'angry, devil' },
		{ value: '🦄', search_text: 'unicorn, magical' },
		{ value: '👫', search_text: 'couple, holding hands' },
		{ value: '🎧', search_text: 'headphones, music' },
		{ value: '🕺', search_text: 'dancing, celebration' },
		{ value: '💫', search_text: 'dizzy, stars' },
		{ value: '👹', search_text: 'monster, ogre' },
		{ value: '🍕', search_text: 'pizza, food' },
		{ value: '🥳', search_text: 'party, celebration' },
		{ value: '💤', search_text: 'sleepy, zzz' },
		{ value: '🌞', search_text: 'sun, sunny' },
		{ value: '😛', search_text: 'tongue, playful' },
		{ value: '🚀', search_text: 'rocket, launch' },
		{ value: '🌹', search_text: 'rose, flower, love' },
		{ value: '🎁', search_text: 'gift, present' },
		{ value: '👨‍💻', search_text: 'coder, programmer' },
		{ value: '😯', search_text: 'surprised, wow' },
		{ value: '💎', search_text: 'diamond, gem' },
		{ value: '🎤', search_text: 'microphone, singing' },
		{ value: '👨‍🎤', search_text: 'rockstar, singer' },
	];

	public filteredEmojis = this.list;

	@Input() public message: IMessage;

	public selectedIndex: number;

	@Output() public modalClosed = new EventEmitter();

	constructor(
		private readonly messagesReactionService: MessageReactionsService,
		private readonly authService: AuthService,
		private readonly messagesWsService: MessagesWsService,
	) {}

	public async setSelectedEmoji(emoji): Promise<void> {
		this.selectedIndex = emoji;

		// Получаем выбранную реакцию
		const selectedReaction = this.list[emoji].value;

		// Удаляем старую реакцию этого пользователя, если она есть
		this.message.reactions = this.message?.reactions?.filter(reaction => {
			return !reaction.users.some(user => user.user_id === this.authService.principal.id);
		});

		// Добавляем новую реакцию
		const result = await this.messagesReactionService.addReaction({
			message_id: this.message.id,
			reaction: selectedReaction,
		});

		// Отправляем обновленное сообщение через WebSocket
		const uuid = generateUID();

		this.messagesWsService.send({
			uuid,
			...this.message,
			reactions: result?.reactions,
		});

		// Закрываем модальное окно
		this.modalClosed.emit();
	}

	public deleteReactionIfUserExist(): void {}

	public handleInput(event): void {
		const searchText = String(event?.target?.value).toLocaleLowerCase('en-US');
		this.filteredEmojis = this.list.filter(emoji => {
			return emoji.search_text.toLocaleLowerCase('en-US').includes(searchText);
		});
	}

	public onSearchClear(): void {
		console.log('clear');
	}
}
