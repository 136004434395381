<ion-header mode="ios">
	<hb-header title="" backUrl="/direct-messages" backUrlText="">
		@if (chatMember) {
			<div
				class="flex ion-align-items-center"
				[routerLink]="chatMember?.is_group ? '/direct-messages/info' : '/card/' + categoryId + '/master'"
				[queryParams]="{ chat: JSON.stringify(chatMember) }"
			>
				<hb-avatar
					size="small"
					slot="start"
					[data]="{ id: chatMember?.user_id, user_name: chatMember?.user_name, img: chatMember?.img }"
					[imgUrl]="chatMember.is_group ? chatMember.img : null"
				></hb-avatar>
				<div>
					<div class="m-l-10">
						{{ chatMember?.user_name }}
					</div>

					<div class="m-l-10 font-size-12 flex typing-signal-box">
						@if (typingSignal()?.sender_info && typingSignal()?.sender_info?.id !== authService?.principal?.id) {
							<div *showExceptMe="typingSignal()?.sender_info.id" class="flex ion-align-items-center">
								<ion-spinner class="m-r-4" name="dots" color="primary" size="small"></ion-spinner>
								<ion-text color="primary">
									{{
										(typingSignal()?.sender_info?.user_name
											? typingSignal()?.sender_info?.user_name
											: typingSignal()?.sender_info?.first_name) + ' '
									}}
									{{ 'typing' | translate }}
								</ion-text>
							</div>
						} @else if (!chatMember?.is_group) {
							<hb-online-last-seen [user]="{ id: chatMember?.user_id, last_seen: chatMember?.last_seen }"></hb-online-last-seen>
						} @else if (chatMember?.member_count) {
							<ion-text color="medium">
								{{ chatMember?.member_count + ' ' + ('MEMBERS' | translate) }}
							</ion-text>
						}
					</div>
				</div>
			</div>

			<ion-buttons slot="end">
				<ion-button id="chat-actions" size="large" color="primary">
					<ion-icon name="ellipsis-horizontal"></ion-icon>
				</ion-button>
			</ion-buttons>

			<ion-popover trigger="chat-actions" [dismissOnSelect]="true">
				<ng-template>
					<hb-chat-actions [chat]="chatMember"></hb-chat-actions>
				</ng-template>
			</ion-popover>
		}
	</hb-header>
</ion-header>

<ion-content #content scrollEvents="true" (ionScroll)="contentScrolled($event)">
	<hb-chat-modal-list
		(replyMessage)="onReplyMessage($event)"
		class="sr-block"
		[categoryId]="categoryId"
		(scrollToBottom)="onMessagesLoaded($event)"
	></hb-chat-modal-list>

	@if (scrollPercentage < 0.5 && scrollPercentage > 0.01) {
		<ion-fab vertical="bottom" horizontal="end" slot="fixed">
			<ion-fab-button color="light" size="small" (click)="scrollToBottomOnInit()">
				<ion-icon color="dark" name="chevron-down"></ion-icon>
			</ion-fab-button>
		</ion-fab>
	}

	<hb-chat-modal-files
		(closedEvent)="onFilesModalClosed($event)"
		[imagePreviews]="imagePreviews"
		[isOpen]="isOpenChatModalFiles"
		[formName]="formName"
	></hb-chat-modal-files>
</ion-content>

<ion-footer mode="ios">
	@if (replyMessage) {
		<ion-toolbar>
			<ion-item color="light" class="reply-message-box">
				<ion-label>
					<div class="font-weight-500">
						{{ replyMessage?.user_info?.user_name ? replyMessage?.user_info?.user_name : (replyMessage?.user_info | fullName) }}
					</div>
					<div class="font-size-14 text-ellipsis">
						{{ replyMessage?.message }}
					</div>
				</ion-label>

				<ion-button color="medium" slot="end" fill="clear" (click)="onReplyMessage(null)">
					<ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
				</ion-button>
			</ion-item>
		</ion-toolbar>
	}

	<ion-toolbar>
		@if (webSocketService.chatId()) {
			<form (submit)="onSuccessForm()" [formConnect]="formName" [formGroup]="form" (onSuccessForm)="onSuccessForm()">
				<div class="flex message-box">
					<ion-buttons slot="start">
						<ion-button color="medium" (click)="onLoadFile()">
							<ion-icon slot="icon-only" name="attach-outline"></ion-icon>
						</ion-button>
					</ion-buttons>

					<ion-list [inset]="true" class="ion-no-margin margin-no w100 ion-no-padding enter-message-box">
						<ion-item color="light">
							<ion-textarea
								rows="1"
								#textarea
								[autoGrow]="true"
								mode="ios"
								[placeholder]="'ENTER_TEXT' | translate"
								formControlName="message"
								class="message-box-textarea"
								(ionFocus)="scrollToBottomOnInit()"
								(ionBlur)="scrollToBottomOnInit()"
								(keydown.enter)="handleEnter($event)"
							></ion-textarea>
						</ion-item>
					</ion-list>

					<ion-buttons slot="end">
						<ion-button color="pink" [disabled]="form.invalid" type="submit">
							<ion-icon slot="icon-only" name="send-outline"></ion-icon>
						</ion-button>
					</ion-buttons>
				</div>
			</form>
		}
	</ion-toolbar>
</ion-footer>
