import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'shortenWallet',
})
export class ShortenWalletPipe implements PipeTransform {
	public transform(wallet: string): string {
		if (!wallet || wallet.length <= 8) return wallet;
		return wallet.slice(0, 8) + ' ... ' + wallet.slice(-8);
	}
}
