<ion-header mode="ios">
	<ion-toolbar>
		<ion-title>{{ 'NOTES_FOR' | translate }} {{ client?.client_name }} <ion-text class="ion-text-lowercase"></ion-text></ion-title>
		<ion-buttons slot="end">
			<ion-button color="medium" (click)="modalCtrl.dismiss()">
				<ion-icon name="close-outline"></ion-icon>
			</ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>

<ion-content>
	<ion-list mode="ios" inset="true">
		<div class="ion-align-items-center flex flex-direction-column ion-margin">
			<hb-avatar slot="start" [data]="{ id: client.client_id, img: client.client_img, user_name: client.client_name }"></hb-avatar>
			<ion-label class="m-b-10 m-t-10">{{ client.client_name }}</ion-label>
		</div>
		<div class="ion-margin-horizontal color-medium m-b-10 font-size-14">
			{{ 'HIDDEN_FOR_CLIENTS' | translate }}
		</div>
	</ion-list>

	@let list = clientsNotesState.res();

	@for (item of list; track $index) {
		<div>
			<div class="ion-text-wrap bubble thought font-size-14 relative">
				{{ item.message }}
				@if (item.master_id === authService?.principal?.id || userRoleService.isAdmin) {
					<ion-button class="trash-btn" mode="ios" fill="clear" (click)="onDelete(item)">
						<ion-icon slot="icon-only" name="trash-outline"></ion-icon>
					</ion-button>
				}
			</div>
			<div class="flex ion-justify-content-start m-l-16">
				<hb-avatar
					size="small"
					class="ion-margin-end"
					[data]="{ user_name: item.master_username, img: item.master_img, id: item.master_id }"
				></hb-avatar>
			</div>
		</div>
	}

	@if (!list?.length) {
		<ion-card mode="ios" class="ion-no-margin ion-margin-horizontal">
			<ion-card-content class="ion-no-padding ion-padding-bottom">
				<div class="flex-row ion-justify-content-center">
					<lottie-player class="lottie-height" slot="end" autoplay loop src="/assets/lottie/no-data.json"></lottie-player>
				</div>
				<ion-text color="medium">
					<div class="ion-text-center font-size-14">
						{{ 'NO_NOTE_FOR_USER' | translate: { userName: client?.client_name } }}
					</div>
				</ion-text>
			</ion-card-content>
		</ion-card>
	}
</ion-content>
<ion-footer mode="ios">
	<ion-toolbar>
		<ion-button expand="full" (click)="presentAlert()" class="ion-no-margin font-size-14" fill="clear" color="dark">
			<ion-icon slot="start" name="add-outline"></ion-icon>
			{{ 'ADD_NOTE' | translate }}
		</ion-button>
	</ion-toolbar>
</ion-footer>
