import { NgClass, NgTemplateOutlet } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { GestureController, IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { generateUID, IMessage, IReactions, MessagesStateService, timeoutUtil } from '@solar/core/src';
import { ChatMembersStateService, MessageReactionsService } from '@solar/core/src/';
import { AuthService } from '@solar/core/src/auth/auth.service';
import { IReactionUser } from '@solar/core/src/interfaces';
import { AvatarComponent } from '../../../../components/stateless/avatar/avatar.component';
import { LocalizedDatePipe } from '../../../../pipes';
import { MessagesWsService } from './../../../../../../core/src/services/ws/messages-ws.service';

import { Browser } from '@capacitor/browser';

const hapticsSelectionChanged = async () => {
	await Haptics.selectionChanged();
};

const openSite = async (url: string): Promise<void> => {
	await Browser.open({ url });
};

@Component({
	selector: 'hb-chat-modal-list-item',
	templateUrl: './chat-modal-list-item.component.html',
	styleUrls: ['./chat-modal-list-item.component.scss'],
	imports: [IonicModule, LocalizedDatePipe, AvatarComponent, RouterModule, NgClass, NgTemplateOutlet, TranslateModule],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatModalListItemComponent implements AfterViewInit {
	@Input() public item: IMessage;
	@Input() public categoryId: number;
	@Input() public showActions?: boolean = true;

	@Output() public onImageLoad = new EventEmitter();

	@Output() public openFile = new EventEmitter();
	@Output() public openEmojis = new EventEmitter();
	@Output() public openActions = new EventEmitter();

	@ViewChild('message', { read: ElementRef }) public message: ElementRef<HTMLElement>;

	private lastOnStart: number = 0;
	private readonly DOUBLE_CLICK_THRESHOLD: number = 500;

	private longPressActive: boolean = false;

	constructor(
		private readonly authService: AuthService,
		private readonly router: Router,
		protected readonly messagesWsService: MessagesWsService,
		protected readonly chatMembersState: ChatMembersStateService,
		private readonly messageReactionsService: MessageReactionsService,
		private readonly gestureCtrl: GestureController,
		public readonly messagesState: MessagesStateService,
	) {}

	public ngAfterViewInit(): void {
		const gesture = this.gestureCtrl.create({
			el: this.message.nativeElement,
			threshold: 0,
			gestureName: 'double-click-and-long-press',
			onStart: () => this.onStart() as unknown as void,
			onEnd: () => {
				this.longPressActive = false;
			},
		});

		gesture.enable();
	}

	public getMessageById(id: number): IMessage | null {
		return this.messagesState.getMessageById(id);
	}

	public onStart(): void {
		this.longPressActive = true;

		void this.longPress().then();

		this.doubleClick();
	}

	public doubleClick(): void {
		const now = Date.now();

		if (Math.abs(now - this.lastOnStart) <= this.DOUBLE_CLICK_THRESHOLD) {
			if (this.item?.reactions?.length === 0) {
				void this.setHeart();
				return;
			}

			for (const r of this.item?.reactions) {
				void this.onOpenAllReactions(r);
			}
			this.lastOnStart = 0;
		} else {
			this.lastOnStart = now;
		}
	}

	public async longPress(): Promise<void> {
		await timeoutUtil();

		if (this.longPressActive) {
			this.openActions.emit(this.item);
			await Haptics.impact({ style: ImpactStyle.Medium });
		}
	}

	public trackByFn(index: number): number {
		return index;
	}

	protected get myId(): number {
		return this.authService?.principal?.id;
	}

	protected formatMessage(message: string): any[] {
		const orderPattern = /\[nav:([^\]]*?):nav\|(.*?)\]/g;
		const urlPattern = /https?:\/\/[^\s]+/g;
		const parts: any[] = [];
		let lastIndex = 0;

		// Обрабатываем форматированные ссылки [nav:...]
		for (const match of message.matchAll(orderPattern)) {
			const [fullMatch, rawUrl, text] = match;
			const offset = match.index;

			// Добавляем текст перед ссылкой
			if (offset > lastIndex) {
				parts.push({ type: 'text', content: message.substring(lastIndex, offset).replace(/\n/g, '<br>') });
			}

			// Обрабатываем ссылку
			const [url, paramString] = rawUrl.split('?');
			const queryParams = paramString
				? Object.fromEntries(
						new URLSearchParams(paramString)
							.toString()
							.split('&')
							.map(param => param.split('=')),
					)
				: {};

			parts.push({ type: 'link', url, queryParams, text });
			lastIndex = offset + fullMatch.length;
		}

		// Обрабатываем оставшийся текст, включая обычные ссылки
		const remainingText = message.substring(lastIndex);
		if (remainingText) {
			const textParts = [];
			let lastTextIndex = 0;

			for (const match of remainingText.matchAll(urlPattern)) {
				const url = match[0];
				const offset = match.index;

				// Добавляем текст перед ссылкой
				if (offset > lastTextIndex) {
					textParts.push(remainingText.substring(lastTextIndex, offset).replace(/\n/g, '<br>'));
				}

				// Добавляем ссылку
				parts.push({ type: 'external-link', url, queryParams: {}, text: url });
				lastTextIndex = offset + url.length;
			}

			// Добавляем остаток текста после всех ссылок
			if (lastTextIndex < remainingText.length) {
				textParts.push(remainingText.substring(lastTextIndex).replace(/\n/g, '<br>'));
			}

			if (textParts.length) {
				parts.push({ type: 'text', content: textParts.join('') });
			}
		}

		return parts;
	}

	private sanitizeQueryParams(queryParams: string): any {
		const params = new URLSearchParams(queryParams);
		const paramsObj = {};
		params.forEach((value, key) => {
			paramsObj[key] = value;
		});
		return paramsObj;
	}

	protected navigate(url: string, queryParams: any, event: Event): void {
		event.preventDefault(); // Prevent the default anchor behavior
		void this.router.navigate([url], { queryParams });
	}

	public sendOpenFile(isOpen: boolean, img: string, id: number, message: string, fileName: string): void {
		this.openFile.emit({ isOpen, img, id, message, fileName });
	}

	public onEmojiClick(message: IMessage): void {
		this.openEmojis.emit({ message, isOpen: true });
	}

	public async onOpenAllReactions(r: IReactions): Promise<void> {
		const isMyReaction = this.isMyReaction(r.users);

		const uuid = generateUID();

		if (isMyReaction) {
			const result = await this.messageReactionsService.deleteReaction({ message_id: this.item.id });

			this.messagesWsService.send({
				...this.item,
				uuid,
				reactions: result?.reactions,
			});
		} else {
			const result = await this.messageReactionsService.addReaction({
				message_id: this.item.id,
				reaction: r.reaction_type,
			});

			this.messagesWsService.send({
				...this.item,
				uuid,
				reactions: result?.reactions,
			});
		}
	}

	public async setHeart(): Promise<void> {
		const uuid = generateUID();

		const result = await this.messageReactionsService.addReaction({
			message_id: this.item.id,
			reaction: '❤️',
		});

		this.messagesWsService.send({
			...this.item,
			uuid,
			reactions: result?.reactions,
		});

		await hapticsSelectionChanged();
	}

	public async openInBrowser(url: string): Promise<void> {
		await openSite(url);
	}

	public isMyReaction(users: IReactionUser[]): boolean {
		return users.some(user => user.user_id === this.myId) as boolean;
	}
}
