import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LoadBatchPage, LoadBatchPageError, LoadBatchPageSuccess } from '@solar/core/src/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { BatchService } from '../../services';

export const loadBatch = 'loadBatch';

@Injectable()
export class BatchEffects {
	public loadBatch$ = this.getBatchEffect(loadBatch, ({ reqData }: { reqData }) => this.batchService.batch(reqData));

	public getBatchEffect(path, makeRequestFn: (action?: any) => Observable<any>) {
		return createEffect(() =>
			this.actions$.pipe(
				ofType(LoadBatchPage),
				switchMap((action: any) => {
					return makeRequestFn(action).pipe(
						map(data => {
							if (data?.length) {
								data.forEach(it => localStorage.setItem(it.id, JSON.stringify(it.data)));
							}
							return LoadBatchPageSuccess({ path, data });
						}),
						catchError(({ error }) => {
							const item = localStorage.getItem(path);
							const storage = item ? JSON.parse(item) : undefined;

							return of(LoadBatchPageError({ path, error, data: storage ? storage.data : null }));
						}),
					);
				}),
			),
		);
	}

	constructor(
		private readonly batchService: BatchService,
		private readonly actions$: Actions,
	) {}
}
