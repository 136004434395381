@let balanceInfo = loadBalanceInfo$ | async;

<ion-header mode="ios">
	<ion-toolbar>
		<ion-title>{{ 'CRYPTO_PAYMENT' | translate }}</ion-title>
		<ion-buttons slot="end">
			<ion-button (click)="closeModal()"> {{ 'CLOSE' | translate }} </ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>

<ion-content>
	@if (balanceInfo?.converted?.best_rate) {
		<div class="font-size-32 m-t-32 ion-margin-horizontal ion-text-center">
			{{ balanceInfo.isPending ? '...' : balanceInfo.converted.result }} {{ from }}
		</div>
		<div class="ion-text-center">
			<ion-note color="medium" class="font-size-12">1 {{ to }} = {{ balanceInfo.converted.best_rate.toFixed(4) }} {{ from }}</ion-note>
		</div>
	}

	<div class="ion-text-center font-size-16 m-t-32 m-b-8 m-l-16 m-r-16">
		<ion-note color="medium">{{ 'SEND' | translate }}</ion-note>
		<ion-note color="medium">
			{{ 'BELOW_ADDRESS_FOR_ORDER' | translate }}
		</ion-note>
	</div>

	<hb-wallet-address [balance]="balanceInfo" walletTextLabel="USDT TRC20"></hb-wallet-address>
</ion-content>

@if (balanceInfo?.converted) {
	<ion-footer>
		<ion-toolbar class="ion-no-padding">
			<div class="m-16">
				<ion-checkbox [disabled]="!balanceInfo?.converted" #confirm mode="md" class="ion-margin-bottom" labelPlacement="end">{{
					'I_CONFIRM_THAT_I_HAVE_SENT' | translate: { amount: balanceInfo.isPending ? '...' : balanceInfo.converted.result, currency: from }
				}}</ion-checkbox>
				<ion-button [disabled]="!confirm.checked" class="ion-no-margin" mode="ios" (click)="confirmPayment()" expand="block">
					{{ 'CONFIRM_PAYMENT' | translate }}
				</ion-button>
			</div>
		</ion-toolbar>
	</ion-footer>
}
