import { UpperCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit, signal } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService, CardModelsEnum, HTTP_BASE_URL, UserRolesEnum, UserSearchStateService } from '@solar/core/src';
import { UserRoleService } from '@solar/core/src/services/user-role/user-role.service';
import { UserRes } from '@solar/core/src/store';
import { addCategoryFormName, CategoriesRes, editCategoryFormName } from '@solar/core/src/store/categories';
import { HeaderComponent } from '../../../components';
import { FormConnectDirective } from '../../../directives/form-connect.directive';
import { SubmitComponent } from '../submit/submit.component';
import { UserSearchComponent } from '../user-search/user-search.component';
import { environment } from './../../../../../../src/environments/environment';

@Component({
	selector: 'hb-add-category',
	templateUrl: './add-category.component.html',
	styleUrls: ['./add-category.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		IonicModule,
		ReactiveFormsModule,
		FormConnectDirective,
		SubmitComponent,
		TranslateModule,
		HeaderComponent,
		UserSearchComponent,
		UpperCasePipe,
	],
})
export class AddCategoryComponent implements OnInit {
	public form: FormGroup;

	public formName: string;

	public imagePreviewUrl: ArrayBuffer | string;

	@Input() public category: CategoriesRes;

	@Input({ required: true }) public model: CardModelsEnum = CardModelsEnum.CATEGORY;

	protected managers = signal<number[]>([]);

	protected isOpenManagerModal = false;

	protected UserRolesEnum = UserRolesEnum;

	constructor(
		private readonly fb: FormBuilder,
		private readonly modalCtrl: ModalController,
		private readonly ch: ChangeDetectorRef,
		@Inject(HTTP_BASE_URL) private readonly httpBaseUrl: string,
		private readonly userSearchStateService: UserSearchStateService,
		protected readonly userRoleService: UserRoleService,
		private readonly authService: AuthService,
	) {}

	public ngOnInit(): void {
		this.form = this.fb.group({
			id: [''],
			title: ['', [Validators.required]],
			title_ru: ['', [Validators.required]],
			img: ['', Validators.required],
			is_active: [false],
			position: [''],
			model: ['', Validators.required],
			managers: [null, Validators.required],
		});

		this.setEditConfigs();
	}

	protected onOpenManagerModal(): void {
		this.isOpenManagerModal = true;
		if (this.category?.managers) {
			this.setManagers(this.category.managers);
		} else if (!this.category && !this.managers().includes(this.authService?.principal?.id)) {
			this.setManagers([this.authService?.principal?.id]);
		}
	}

	protected setManagers(managers: number[]): void {
		this.managers.set(managers);
		this.userSearchStateService.items.set(this.managers());

		this.form.get('managers').setValue(this.managers());
	}

	public setEditConfigs(): void {
		if (this.category) {
			if (this.category.model) {
				this.model = this.category.model as CardModelsEnum;
			}

			this.formName = editCategoryFormName;

			this.form.patchValue(this.category);

			this.imagePreviewUrl = `${environment.s3Url}/${this.category.img}`;

			if (this.category?.managers) {
				this.setManagers(this.category.managers);
			}
		} else {
			this.formName = addCategoryFormName;

			this.form.get('model').setValue(this.model);

			this.setManagers([this.authService?.principal?.id]);
		}
		this.ch.detectChanges();
	}

	public async onSuccessForm(ev): Promise<void> {
		this.form.reset();

		await this.modalCtrl.dismiss(ev);
	}

	public onSelectFile(ev): void {
		if (ev.target.files.length > 0) {
			const parent = document.querySelector('.file-upload-wrapper');
			const val: string = ev.target.value.replace(/.*(\/|\\)/, '');
			const file: File = ev.target.files[0];
			const reader: FileReader = new FileReader();

			parent.setAttribute('data-text', val);

			this.form.get('img').setValue(file);
			// POST formData to server using HttpClient

			reader.onloadend = () => {
				this.imagePreviewUrl = reader.result;

				this.ch.detectChanges();
			};

			reader.readAsDataURL(file);
		} else {
			const parent = document.querySelector('.file-upload-wrapper');
			parent.setAttribute('data-text', '');
			this.form.get('img').setValue(null);
			this.imagePreviewUrl = null;
		}
	}
	public async onClose(): Promise<void> {
		await this.modalCtrl.dismiss(false);
	}

	public onDismiss(ev: UserRes[]): void {
		this.isOpenManagerModal = false;

		if (ev?.length) {
			this.setManagers(ev.map(u => u.id));
		} else {
			this.setManagers([]);
		}

		this.form.get('managers').setValue(this.managers());
	}
}
