import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpParams, HttpRequest } from '@angular/common/http';
import { InjectionToken, inject } from '@angular/core';
import { Observable } from 'rxjs';

export type HttpParamsFactory = () => HttpParams;

export const HTTP_PARAMS_CONFIG = new InjectionToken<HttpParamsFactory>('HTTP_PARAMS_CONFIG');

/**
 * Функциональный интерцептор для добавления параметров запроса (рекомендуемый в Angular 19+)
 */
export const httpParamsInterceptor: HttpInterceptorFn = (
	request: HttpRequest<unknown>,
	next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
	const httpParamsFactory = inject(HTTP_PARAMS_CONFIG);

	let httpParams = httpParamsFactory();

	const originalParams = request.params;

	originalParams.keys().forEach(key => {
		// Получаем все значения для параметра (если их несколько)
		const values = originalParams.getAll(key);

		if (values.length > 1) {
			// Если у параметра несколько значений, добавляем каждое
			values.forEach(value => {
				httpParams = httpParams.append(key, value);
			});
		} else {
			// Если у параметра одно значение
			httpParams = httpParams.append(key, originalParams.get(key));
		}
	});

	// Клонируем запрос с новыми параметрами
	const clonedRequest = request.clone({
		params: httpParams,
	});

	return next(clonedRequest);
};
