import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { MasterInfo, ResetForm } from '@solar/core/src/store';
import { CategoriesRes } from '@solar/core/src/store/categories';
import { addServiceFormName, CategoryServicesRes, editServiceFormName } from '@solar/core/src/store/category-services';
import { UserRolesEnum } from '@solar/enums/user-roles.enum';
import { SubmitComponent } from '../submit/submit.component';
import { MastersService } from './../../../../../../src/app/core/services/masters.service';
import { environment } from './../../../../../../src/environments/environment';
import { CategoriesSelectComponent } from './../categories-select/categories-select.component';

import { Store } from '@ngrx/store';
import { HTTP_BASE_URL, IAppState, ICollectionResponse } from '@solar/core/src';
import { CardModelsEnum } from '@solar/enums';
import { HeaderComponent } from '../../../components';
import { FormConnectDirective } from '../../../directives/form-connect.directive';
@Component({
	selector: 'hb-add-service',
	templateUrl: './add-service.component.html',
	styleUrls: ['./add-service.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		IonicModule,
		ReactiveFormsModule,
		FormConnectDirective,
		CategoriesSelectComponent,
		SubmitComponent,
		TranslateModule,
		HeaderComponent,
	],
})
export class AddServiceComponent implements OnInit, OnDestroy {
	public form: FormGroup;

	public formName: string;

	public imagePreviewUrl: ArrayBuffer | string;

	@Input() public model: CardModelsEnum;
	@Input() public category: CategoriesRes;
	@Input() public master: MasterInfo;
	@Input() public masters: MasterInfo[];
	@Input() public service: CategoryServicesRes;

	constructor(
		private readonly fb: FormBuilder,
		private readonly modalCtrl: ModalController,
		private readonly ch: ChangeDetectorRef,
		@Inject(HTTP_BASE_URL) private readonly httpBaseUrl: string,
		private readonly store: Store<IAppState>,
		private readonly mastersService: MastersService,
	) {}

	public ngOnInit(): void {
		this.setFormName();

		this.form = this.fb.group({
			id: [''],
			title: ['', Validators.required],
			title_ru: [''],
			currency: ['EUR', Validators.required],
			category_id: [''],
			type: [''],
			master_id: ['', Validators.required],
			price: ['', Validators.required],
			masters: [''],
			price_to: [null],
			img: [''],
			description: [''],
			stock_available: [''],
			vat: [''],
		});

		this.addServiceTimeControl();

		this.setEditConfigs();

		if (this.model === CardModelsEnum.CATEGORY || this.model === CardModelsEnum.SHOP) {
			void this.mastersService
				.getAllMasters({
					is_active: true,
					random_sort: true,
					category_id: [this.category.id],
				})
				.then((res: ICollectionResponse<MasterInfo>): void => {
					this.masters = res?.data;

					this.ch.detectChanges();
				});
		}
	}

	public onSelectFile(ev): void {
		const parent = document.querySelector('.file-upload-wrapper');
		const val: string = ev.target.value.replace(/.*(\/|\\)/, '');
		const file: File = ev.target.files[0];
		const reader: FileReader = new FileReader();

		parent.setAttribute('data-text', val);

		this.form.get('img').setValue(file);
		// POST formData to server using HttpClient

		reader.onloadend = () => {
			this.imagePreviewUrl = reader.result;

			this.ch.detectChanges();
		};

		reader.readAsDataURL(file);
	}

	public addServiceTimeControl(): void {
		if (this.model !== CardModelsEnum.SHOP) {
			this.form.addControl('service_time', new FormControl(['', Validators.required]));
		}
	}

	public setEditConfigs(): void {
		if (this.masters && this.service?.masters?.length) {
			this.form.get('masters').setValue(this.service?.masters);
		}

		if (this.category || this.master) {
			this.form.patchValue({
				category_id: this.category?.id,
				master_id: this?.master?.id,
			});
		}

		if (this.category?.type) {
			this.form.patchValue({ type: this.category?.type });
		}

		if (this.service) {
			this.form.addControl('position', new FormControl());

			this.form.patchValue(this.service);
			this.imagePreviewUrl = `${environment.s3Url}/${this.service.img}`;
		}
	}

	public setFormName(): void {
		if (this.service) {
			this.formName = editServiceFormName;
		} else {
			this.formName = addServiceFormName;
		}
	}

	public onTypeChange(ev): void {
		this.form.patchValue({ type: ev.type });
	}

	public async onSuccessForm(ev): Promise<void> {
		await this.modalCtrl.dismiss(ev);
	}

	public async onClose(): Promise<void> {
		await this.modalCtrl.dismiss(false);
	}

	public ngOnDestroy(): void {
		this.store.dispatch(ResetForm({ formName: this.formName }));
	}

	protected readonly UserRolesEnum = UserRolesEnum;
	protected readonly CardModelsEnum = CardModelsEnum;
}
