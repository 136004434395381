import { isEmptyValue } from '../checkers';

export function rmEmptyVal(obj: Record<string, any>): object {
	for (const it in obj) {
		if (isEmptyValue(obj[it])) {
			delete obj[it];
		}
	}
	return obj;
}

export function _delete(obj, prop): void {
	if (Array.isArray(obj[prop]) && !obj[prop].length) {
		delete obj[prop];
	}
}
